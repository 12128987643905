export default {
  init: function() {
    this.SetDataBackgrounds();
    this.ChangeFont();
    this.SetDataURLs();
  },
  SetDataBackgrounds: () => {
    $('[data-background]').each(function() {
      var attr, that;
      that = $(this);
      attr = that.attr('data-background');
      that.css('background-image', "url(" + attr + ")");
    });
  },
  ChangeFont: () => {
  	$('.change-font').on('click', 'a', function (e) {
      e.preventDefault();
      $('.change-font a.size').removeClass('active');
      $(this).addClass('active');
      if ($(this).hasClass('font-size-1')) {
        $('body, .fs-14p, .fs-15p, .fs-16p, .fs-18p, .fs-19p, .fs-20p, h1, h2, h3, h4, h5, h6, h1, .h2, .h3, .h4, .h5, .h6, .btn').removeClass('text-size-2');
        $('body, .fs-14p, .fs-15p, .fs-16p, .fs-18p, .fs-19p, .fs-20p, h1, h2, h3, h4, h5, h6, h1, .h2, .h3, .h4, .h5, .h6, .btn').removeClass('text-size-3');
        $('body, .fs-14p, .fs-15p, .fs-16p, .fs-18p, .fs-19p, .fs-20p, h1, h2, h3, h4, h5, h6, h1, .h2, .h3, .h4, .h5, .h6, .btn').addClass('text-size-1');
      } else if ($(this).hasClass('font-size-2')) {
        $('body, .fs-14p, .fs-15p, .fs-16p, .fs-18p, .fs-19p, .fs-20p, h1, h2, h3, h4, h5, h6, h1, .h2, .h3, .h4, .h5, .h6, .btn').removeClass('text-size-1');
        $('body, .fs-14p, .fs-15p, .fs-16p, .fs-18p, .fs-19p, .fs-20p, h1, h2, h3, h4, h5, h6, h1, .h2, .h3, .h4, .h5, .h6, .btn').removeClass('text-size-3');
        $('body, .fs-14p, .fs-15p, .fs-16p, .fs-18p, .fs-19p, .fs-20p, h1, h2, h3, h4, h5, h6, h1, .h2, .h3, .h4, .h5, .h6, .btn').addClass('text-size-2');
      } else if ($(this).hasClass('font-size-3')) {
        $('body, .fs-14p, .fs-15p, .fs-16p, .fs-18p, .fs-19p, .fs-20p, h1, h2, h3, h4, h5, h6, h1, .h2, .h3, .h4, .h5, .h6, .btn').removeClass('text-size-2');
        $('body, .fs-14p, .fs-15p, .fs-16p, .fs-18p, .fs-19p, .fs-20p, h1, h2, h3, h4, h5, h6, h1, .h2, .h3, .h4, .h5, .h6, .btn').removeClass('text-size-1');
        $('body, .fs-14p, .fs-15p, .fs-16p, .fs-18p, .fs-19p, .fs-20p, h1, h2, h3, h4, h5, h6, h1, .h2, .h3, .h4, .h5, .h6, .btn').addClass('text-size-3');
      }
  	});
  },
  SetDataURLs: () => {
    if ($('[data-url]')) {
      $('[data-url]').each(function() {
        var url, that;
        that = $(this);
        url = that.attr('data-url');
        that.on('click', function() {
          window.open(url, '_blank');
        });
      });
    }
    
    if ($('[data-url-internal]')) {
      $('[data-url-internal]').each(function() {
        var url, that;
        that = $(this);
        url = that.attr('data-url-internal');
        that.on('click', function() {
          window.location = url;
        });
      });
    }
  }
}