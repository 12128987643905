import Popper from 'popper.js';

export default {
  init: function() {
    this.InitMobileNavSlider();
    this.NavSticky();
    this.InitNavActiveState();
    this.InitRemoveMegaMenuSlider();
    this.NavDropdown();
  },
  InitMobileNavSlider: () => {
    if ($('.toggle-nav')) {
      $('.toggle-nav').on('click', function (e) {
        e.preventDefault();
        $('.mobile-menu').addClass('active');
      });
      $('.mobile-menu .close').on('click', function (e) {
        e.preventDefault();
        $('.mobile-menu').removeClass('active');
      });
    }
  },
  NavSticky: () => {
    const nav = document.querySelector('#fixed-nav');
    const navOffsetTop = nav.offsetTop;
    let previousScroll = window.scrollY;

    window.addEventListener('scroll', () => {
      const currentScroll = window.scrollY;
      if ( currentScroll > navOffsetTop ) {
        nav.classList.add('sticky', 'box-shadow');
      } else {
        nav.classList.remove('sticky', 'box-shadow');
      }
      previousScroll = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
    });
  },
  InitNavActiveState: () => {
    if (location.pathname != '/') {
      $('.main-nav li a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active');
    }
  },
  InitRemoveMegaMenuSlider: () => {
    var megaDropdown = $('.dropdown');
    var megaDropdownLink = $('.dropdown a');
    if (megaDropdown) {
      $(megaDropdownLink).on('click', function (e) {
        $('.dropdown-menu').removeClass('show');
      });
    }
  },
  NavDropdown: () => {
    const dropdown3 = document.querySelector('#dropdownMenu3');
    const dropdownMenuLocate = document.querySelector('.dropdownMenuLocate');

    if (dropdown3) {
      var popper = new Popper(dropdown3, dropdownMenuLocate, {
        options: {
         offset: '43, 430',
        },
      });
      dropdownMenuLocate.style.transform = 'translate3d(-344px, 43px, 0)';
    }
  }
}