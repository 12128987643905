'use strict';

// Packages
import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'slick-carousel';

// Components
import Slider from './components/slider';
import Translate from './components/translate';
import Nav from './components/nav';
import Utils from './utils/utils';

const App = {
  init: function() {
    Translate.init();
    Nav.init();
    Slider.init();
    Utils.init();
  }
}

$(function() {
  App.init();
});