export default {
  init: function() {
    this.InitHeroSlider();
    this.InitMapSlider();
    this.InitVideoSlider();
    this.InitPartnerSlider();
    this.InitFactsSlider();
  },
  InitHeroSlider: () => {
    if ($('.header-slider').length) {
      $('.header-slider').slick({
        arrows: false,
        dots: true,
        fade: true,
        appendDots: '#dots-container .container'
      });
    }
  },
  InitMapSlider: () => {
    if ($('.map-slider').length) {
      $('.map-slider').slick({
        arrows: false,
        dots: true,
        fade: true,
        appendDots: '#dots-container-center .container'
      });
      $('.map-slider--prev').each(function() {
        $(this).on('click', function() {
          $('.map-slider').slick('slickPrev');
        });
      });
      $('.map-slider--next').each(function() {
        $(this).on('click', function() {
          $('.map-slider').slick('slickNext');
        });
      });
    }
  },
  InitVideoSlider: () => {
    if ($('.video-slider').length) {
      $('.video-slider').slick({
        arrows: false,
        fade: true,
      });
      $('.video-slider--prev').each(function() {
        $(this).on('click', function() {
          $('.video-slider').slick('slickPrev');
        });
      });
      $('.video-slider--next').each(function() {
        $(this).on('click', function() {
          $('.video-slider').slick('slickNext');
        });
      });
    }
  },
  InitPartnerSlider: () => {
    if ($('.partners-slider').length) {
      $('.partners-slider').slick({
        arrows: false,
        autoplay: true,
        slidesToShow: 6,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 4
            }
          }, {
            breakpoint: 767,
            settings: {
              slidesToShow: 3
            }
          }, {
            breakpoint: 576,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      });
      $('.partners-slider--prev').each(function() {
        $(this).on('click', function() {
          $('.partners-slider').slick('slickPrev');
        });
      });
      $('.partners-slider--next').each(function() {
        $(this).on('click', function() {
          $('.partners-slider').slick('slickNext');
        });
      });
    }
  },
  InitFactsSlider: () => {
    if ($('.facts-slider').length && window.matchMedia('(max-width: 768px)').matches) {
      $('.facts-slider').slick({
        arrows: false,
        dots: true,
        fade: true,
        appendDots: '#dots-container-center-sm .container',
      });
    }
  },
}