export default {
  init: function() {
    if ($('.translate-list a')) {
      this.InitTranslate();
    }
  },
  InitTranslate: () => {
    console.log($('.translate-list a'));
    $('.translate-list a').click(function(e) {
      e.preventDefault();
      window.location = $(this).attr('href');
      location.reload();
    });
  }
}